export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
  },
  {
    title: "About",
    url: "/about",
    cName: "nav-links",
  },
  {
    title: "Projects",
    url: "/projects",
    cName: "nav-links",
  },
  // {
  //     title: "Resume",
  //     url: "/resume",
  //     cName: "nav-links"
  // },
];
